require( "core-js" );

require( "axios" );
require( "history" );
require( "redux" );
require( "redux-saga" );
require( "react" );
require( "react-dom" );
require( "react-redux" );
require( "react-router" );
require( "react-router-redux" );